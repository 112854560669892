import React from 'react';

const Summary = () => {
  return (
    <div className="p-summary u-mt--30">
      <div className="p-summary__inner">
        <h2 className="c-text--h2-ttl">WHAT IS WLF TRIBE?</h2>

        <p className="c-text u-mt--30 u-mt--20-sp">
          WLF TRIBE is a NFT game where players react on their socials to win.
          <br />
          Send reactions through your social media account to send "BAN points"
          to the opposing team and bring your team to victory.
          <br />
          The more "BAN points" your opponent team accumulates, the more likely
          you win!
        </p>

        <p className="p-summary__img u-mt--70 u-mt--40-sp">
          <img src="images/img_summary.jpg" alt="" />
        </p>
      </div>
    </div>
  );
};

export default Summary;
