import React from 'react';

const Schedule = () => {
  return (
    <section id="schedule" className="p-schedule u-mt--200 u-mt--80-sp">
      <h2 className="c-text--h2-ttl">SCHEDULE</h2>

      <p className="u-mt--50 u-mt--30-sp">
        <img src="images/img_schedule.jpg" alt="" />
      </p>

      <h3 className="c-text--h3-ttl u-mt--40 u-mt--20-sp">
        WLF TRIBE Season1 on 12, Dec, 2023
      </h3>

      <p className="c-text u-mt--40 u-mt--20-sp">
        NFT Mint Term: 12, Dec. 2023 - 21, Dec. 2023 <br />
        Social Media Battle: 18, Dec. 2023 - 22, Dec. 20233 <br />
        NFT Reveal: 22, Dec. 20233 <br />
        Game Result: 23, Dec. 20233 <br />
      </p>
    </section>
  );
};

export default Schedule;
