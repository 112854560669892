import React from 'react';
import ContactCard from 'components/common/ContactCard';
import 'assets/css/main.css';

const Footer = () => {
  const items = [
    { href: 'mailto:info@wwwc.app', image: '/images/common/img_email.png' },
    {
      href: 'https://twitter.com/WLF_Project',
      image: '/images/common/img_twitter.svg',
      isExternal: true,
    },
    {
      href: 'https://discord.gg/wlf-project',
      image: '/images/common/img_discord.svg',
      isExternal: true,
    },
  ];

  return (
    <footer className="l-footer">
      <div className="l-footer__inner">
        <nav className="l-footer__nav">
            <ul className="l-footer__nav-list">
              {items.map((item, i) => (
                <li className="l-footer__nav-item" key={i}>
                  <a
                    href={item.href}
                    className="l-footer__nav-item-link"
                    {...(item.isExternal ? { target: "_blank", rel: "noopener noreferrer" } : {})}
                  >
                    <img src={item.image} alt="" aria-hidden="true" />
                  </a>
                </li>
              ))}
            </ul>
          </nav>

        <div className="l-footer__title">
          <p className="l-footer__title-img">
            <a href="https://wlfproject.com/" target="_blank" rel="noopener noreferrer">
              <img src="/images/common/icon_footer_logo.svg" alt="" />
            </a>
          </p>
        </div>
      </div>
      <img type="image/png" href="images/favicon.png" />
    </footer>
  );
};

export default Footer;
