import React from 'react';

const Rule = () => {
  return (
    <section id="rule" className="p-rule u-mt--110 u-mt--80-sp">
      <div className="p-rule__inner">
        <h2 className="c-text--h2-ttl">GAME RULE</h2>

        <div className="p-rule__howto u-mt--60 u-mt--40-sp">
          <h3 className="c-text--h3-ttl">HOW TO WIN</h3>
          <p className="c-text u-mt--30 u-mt--20-sp">
            The game is played in a 4 day period. <br />
            On the 4th day, the team with the lowest “BAN point” wins. (If a
            “reverse victory condition (reverse WIN)” is met, that team wins.
            More on this later.) <br />
            The winning team receives the most rewards.
          </p>
        </div>

        <div className="p-rule__rules u-mt--120 u-mt--40-sp">
          <h3 className="c-text--h3-ttl">RULES</h3>
          <p className="c-text u-mt--20">
            ● Humans / Werewolves <br />
            Each team sends each other "BAN points". At the end of the game, the
            team with less "BAN points" wins.
            <br />
            <br />
            ● Vampires <br />
            If the winning team's "BAN points" are more than 40% of the "BAN
            points" total, the Vampires win - this is the “reverse victory
            condition (reverse WIN)”. On all other occasions, the Vampires lose.
          </p>

          <p className="p-rule__rules-ex c-text">
            Example (Humans win): <br />
            BAN points total at end of game: <br />
            Werewolves: 1000 BAN points <br />
            Humans: 400 BAN points <br />
            Vampires: 100 BAN points <br />
            The winning team’s BAN point ration is 400/(1000+400+100) = 26.6%{' '}
            <br />
            The winning team’s BAN point ratio is below 40%. The Humans win.
          </p>

          <p className="p-rule__rules-ex c-text">
            Example (Vampires win): <br />
            BAN points total at end of game:
            <br />
            <br />
            Werewolves: 1000 BAN points <br />
            Humans: 900 BAN points <br />
            Vampires: 100 BAN points <br />
            The winning team’s BAN point ration is 900/(1000+900+100) = 45%{' '}
            <br />
            The winning team’s BAN point ratio is above 40%. The Vampires
            reverse WIN.
          </p>
        </div>

        <div className="p-rule__directions u-mt--110 u-mt--40-sp">
          <h3 className="c-text--h3-ttl">DIRECTIONS</h3>

          <p className="p-rule__directions-box-text c-text u-mt--20">
            1. Which team am I? <br />
            When you receive your NFT, you can choose which team you wish to
            belong to: werewolves, humans, or vampires. <br />
            When you join a team, 100 "BAN points" get added to the team you
            chose.
            <br />
            <br />
            2. Send your opponents “BAN points” by reacting to posts <br />
            You can send your opponents “BAN points” everyday by reacting to
            posts on the WLF TRIBE X (twitter). <br />
            {'Like => “BAN points” will be added to the werewolves'} <br />
            {'Repost or Reply => “BAN points” will be credited to the humans'}
          </p>

          <p className="p-rule__directions-ex c-text u-mt--60 u-mt--40-sp">
            How “BAN points” are calculated everyday: <br />
            The total number of NFTs issued on that day x 20 becomes the total
            BAN points for that day. Those BAN points will be distributed in the
            ratio of “Likes”: “Reposts ” as follows
            <br />
            <br />
            For example: <br />
            Total number of NFTs issued on that day: 30 <br />
            Likes: 50 <br />
            Reposts: 20
            <br />
            <br />
            30 (total NFTs issued) x 20 = 600 (total BAN points for the day){' '}
            <br />
            Percentage of BAN points for werewolves: 50 (likes)/70 (likes +
            reposts) = 71.4% <br />
            600 x 71.4% = 428 BAN points will be added to the werewolves
            <br />
            <br />
            Percentage of BAN points to the humans: 20 (reposts)/70 (likes +
            reposts) = 28.6% <br />
            600 x 28.6% = 172 BAN points will be added to the humans
            <br />
            <br />
            *Rounded to two decimal places for percentages, rounded to one
            decimal place for points.
          </p>

          <p className="c-text u-mt--30">
            <h3 className="c-text--medium">Note</h3>
            New posts on X (twitter) will be posted daily. React to those posts.{' '}
            <br />
            Reacting to old posts will NOT add BAN points.
            <br />
            <br />
            <h3 className="c-text--medium">Reward Distribution</h3>
              Winning Team’s NFT holders: get to divide $8,000 amongst each other <br />
          </p>
        </div>
      </div>
    </section>
  );
};

export default Rule;
