import React from 'react';

const Reword = () => {
  const maxWidth = 768;
  const notes = [
    '*WLF token rewards will be distributed after the WLF token has been listed on an exchange.',
    '*Once the game ends, NFTs will become SBTs (Soul Bond Tokens) - tokens that will not be able to be sent to others.',
  ];

  return (
    <div className="p-reword">
      <div className="p-reword__inner">
        <p className="p-reword__img">
          <img src="images/img_reword.png" alt="" aria-hidden="true" />
        </p>

        <div className="c-list--note">
          <ul className="c-list">
            {notes.map((note, i) => (
              <li className="c-list__item" key={i}>
                {note}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Reword;
